import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "Home" */ '../views/Home.vue')
  },
  {
    path: '/development',
    name: 'development',
    component: () => import(/* webpackChunkName: "Development" */ '../views/Development.vue')
  },
  {
    path: '/careers',
    name: 'careers',
    component: () => import(/* webpackChunkName: "Careers" */ '../views/Careers.vue')
  },
  {
    path: '/hosting',
    name: 'hosting',
    component: () => import(/* webpackChunkName: "Hosting" */ '../views/Hosting.vue')
  },
  {
    path: '/about-us',
    name: 'about-us',
    component: () => import(/* webpackChunkName: "AboutUs" */ '../views/AboutUs.vue')
  },
  {
    path: '/clients',
    name: 'clients',
    component: () => import(/* webpackChunkName: "Clients" */ '../views/Clients.vue')
  },
  {
    path: '/clients/:alias',
    name: 'case-study',
    component: () => import(/* webpackChunkName: "Clients" */ '../views/ClientDetail.vue'),
    props: true
  },
  {
    path: '/subsidiary/:alias',
    name: 'subsidiary',
    component: () => import(/* webpackChunkName: "Subsidiary" */ '../views/Subsidiary.vue'),
    props: true
  },
  {
    path: '/terms-of-service',
    name: 'terms-of-service',
    component: () => import(/* webpackChunkName: "TermsOfService" */ '../views/TermsOfService.vue'),
    props: true
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import(/* webpackChunkName: "PrivacyPolicy" */ '../views/PrivacyPolicy.vue'),
    props: true
  },
  {
    path: '/404',
    name: 'PageNotFound',
    component: () => import(/* webpackChunkName: "PageNotFound" */ '../views/PageNotFoundView.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: "/404"
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // Exists when Browser's back/forward pressed
    if (savedPosition) {
      return savedPosition
    // For anchors
    } else if (to.hash) {
      return { el: to.hash }
    // By changing queries we are still in the same component, so "from.path" === "to.path" (new query changes just "to.fullPath", but not "to.path").
    } else if (from.path === to.path) {
      return {}
    }

    if (from && Object.keys(to.query).length) {
      if (to.fullPath.split('?')[0] == from.fullPath.split('?')[0]) return;
    }

    return { top: 0, behavior: 'smooth' }; 
  }
})

export default router