<template>
  <Navbar />
  <div class="contact-us-icon sticky-bottom">
    <router-link to="/#ContactUs" class="get-in-touch-container position-fixed">
      <div class="bubble">Get in Touch!</div>
    </router-link>
  </div>
  <main>
    <router-view />
  </main>
  <Footer />
</template>

<script setup lang="ts">
  import { onMounted } from "vue"
  import { useHead } from "@unhead/vue"
  import Navbar from "@/components/navigation/Navbar.vue"
  import Footer from "@/components/navigation/Footer.vue"
  import router from "./router"
  import { setMetaData } from "@/utils/functions"
  import { useApolloClient } from '@vue/apollo-composable'

  const { resolveClient } = useApolloClient()
  const ApolloClient = resolveClient()

  useHead({
    titleTemplate: (titleChunk) => {
      return titleFormat(titleChunk);
    },
    meta: [{ name: "description", content: "We're Nologo Studios. We believe in developing and implementing clear strategies for how digital can grow your business and achieve your goals." }],
  })

  onMounted(() => {
    getMetaData(router.currentRoute.value.path)
  })

  router.afterEach((to, from) => {
    if (from.path !== to.path) {
      getMetaData(to.path)
    }
  })

  function getMetaData (path: string) {
    setMetaData(path == "/" ? "home" : path, ApolloClient)
  }

  function titleFormat (titleChunk: any) {
    const prependString = router.currentRoute.value.path == "/" ? "" : " | Nologo Studios"
    return titleChunk ? titleChunk + prependString : "Nologo Studios"
  }
</script>

<style lang="scss">
  @import "~bootstrap/scss/bootstrap.scss";
</style>