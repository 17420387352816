import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "contact-us-icon sticky-bottom" }

import { onMounted } from "vue"
  import { useHead } from "@unhead/vue"
  import Navbar from "@/components/navigation/Navbar.vue"
  import Footer from "@/components/navigation/Footer.vue"
  import router from "./router"
  import { setMetaData } from "@/utils/functions"
  import { useApolloClient } from '@vue/apollo-composable'

  
export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

  const { resolveClient } = useApolloClient()
  const ApolloClient = resolveClient()

  useHead({
    titleTemplate: (titleChunk) => {
      return titleFormat(titleChunk);
    },
    meta: [{ name: "description", content: "We're Nologo Studios. We believe in developing and implementing clear strategies for how digital can grow your business and achieve your goals." }],
  })

  onMounted(() => {
    getMetaData(router.currentRoute.value.path)
  })

  router.afterEach((to, from) => {
    if (from.path !== to.path) {
      getMetaData(to.path)
    }
  })

  function getMetaData (path: string) {
    setMetaData(path == "/" ? "home" : path, ApolloClient)
  }

  function titleFormat (titleChunk: any) {
    const prependString = router.currentRoute.value.path == "/" ? "" : " | Nologo Studios"
    return titleChunk ? titleChunk + prependString : "Nologo Studios"
  }

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(Navbar),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_router_link, {
        to: "/#ContactUs",
        class: "get-in-touch-container position-fixed"
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("div", { class: "bubble" }, "Get in Touch!", -1)
        ])),
        _: 1
      })
    ]),
    _createElementVNode("main", null, [
      _createVNode(_component_router_view)
    ]),
    _createVNode(Footer)
  ], 64))
}
}

})