<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-white sticky-top" id="navbar">
        <div class="container-lg">
            <router-link class="navbar-brand logo-style" to="/" @click="homeScroll()">
                <span class="text-primary">/</span>
                <span class="text-secondary">/</span>
                <span class="text-tertiary">/</span>
                nologo studios
            </router-link>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNavDropdown">
                <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                    <li class="nav-item">
                        <router-link class="nav-link" to="/clients">Clients</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/careers">Careers</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/development">Development</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/hosting">Hosting</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/about-us">About Us</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/#ContactUs">Contact Us</router-link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script setup lang="ts">
    import { scrollTop } from "@/utils/functions"
    function homeScroll () {
        if(window.scrollY == 0) {
            return
        }
        
        setTimeout(() => {
            scrollTop('')
        }, 50)
    }
</script>