import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, unref as _unref, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "footer mt-auto pt-5",
  style: {"background-color":"#212529"}
}
const _hoisted_2 = { class: "nav justify-content-center pb-3 mb-5" }
const _hoisted_3 = { class: "nav-item" }
const _hoisted_4 = { class: "nav-item" }
const _hoisted_5 = { class: "nav-item" }
const _hoisted_6 = {
  class: "px-3 px-md-5 py-4",
  style: {"background-color":"#0f0f0f"}
}
const _hoisted_7 = { class: "nav px-1 px-md-5 flex-column flex-md-row justify-content-center text-center" }
const _hoisted_8 = { class: "nav-item px-3" }
const _hoisted_9 = {
  class: "text-white px-2 py-2 d-block",
  style: {"font-size":"small"}
}
const _hoisted_10 = { class: "nav-item px-3" }
const _hoisted_11 = ["href"]
const _hoisted_12 = { class: "nav-item px-3" }

import { CmsBaseUrl } from "@/utils/functions"

export default /*@__PURE__*/_defineComponent({
  __name: 'Footer',
  setup(__props) {

    
return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _cache[10] || (_cache[10] = _createStaticVNode("<p class=\"text-center text-white pb-3 logo-style navbar-brand\">/// nologo studios</p><p class=\"text-center px-3\">Block C, Ground Floor, Essex Park, 46 Essex Terrace, Westville, 3629</p><ul class=\"nav justify-content-center pb-4 mb-4\"><li class=\"nav-item\"><a href=\"tel:+27312660245\" class=\"px-2\">+27 (0)31 266 0245</a></li><li class=\"nav-item\"><a href=\"tel:+27866011187\" class=\"px-2\">+27 (0)86 601 1187</a></li><li class=\"nav-item px-2\"><a href=\"mailto:info@nologostudios.com\" class=\"px-2\">info@nologostudios.com</a></li></ul>", 3)),
    _createElementVNode("ul", _hoisted_2, [
      _createElementVNode("li", _hoisted_3, [
        _createVNode(_component_router_link, {
          to: "/about-us",
          class: "px-2"
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("About Us")
          ])),
          _: 1
        })
      ]),
      _cache[3] || (_cache[3] = _createElementVNode("li", { class: "nav-item px-2" }, "|", -1)),
      _createElementVNode("li", _hoisted_4, [
        _createVNode(_component_router_link, {
          to: "/privacy-policy",
          class: "px-2"
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("Privacy Policy")
          ])),
          _: 1
        })
      ]),
      _cache[4] || (_cache[4] = _createElementVNode("li", { class: "nav-item px-2" }, "|", -1)),
      _createElementVNode("li", _hoisted_5, [
        _createVNode(_component_router_link, {
          to: "/#ContactUs",
          class: "px-2"
        }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode("Contact Us")
          ])),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("ul", _hoisted_7, [
        _createElementVNode("li", _hoisted_8, [
          _createElementVNode("span", _hoisted_9, "© " + _toDisplayString(new Date().getFullYear()) + " Nologo Studios", 1)
        ]),
        _cache[6] || (_cache[6] = _createStaticVNode("<li class=\"nav-item d-none d-md-block px-1 text-muted\">|</li><li class=\"nav-item px-3\"><span class=\"text-white px-2 py-2 d-block\" style=\"font-size:small;\">Microsoft <span style=\"color:#D8A925;\">Gold</span> Partner</span></li><li class=\"nav-item d-none d-md-block px-1 text-muted\">|</li>", 3)),
        _createElementVNode("li", _hoisted_10, [
          _createElementVNode("a", {
            href: _unref(CmsBaseUrl)() + '/media/Nologo-Studios-PAIA-Manual.pdf',
            target: "_blank",
            class: "text-white px-2 py-2 d-block",
            style: {"font-size":"small"}
          }, "PAIA", 8, _hoisted_11)
        ]),
        _cache[7] || (_cache[7] = _createElementVNode("li", { class: "nav-item d-none d-md-block px-1 text-muted" }, "|", -1)),
        _createElementVNode("li", _hoisted_12, [
          _createVNode(_component_router_link, {
            to: "/privacy-policy",
            class: "text-white px-2 py-2 d-block",
            style: {"font-size":"small"}
          }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createTextVNode("Privacy Policy")
            ])),
            _: 1
          })
        ]),
        _cache[8] || (_cache[8] = _createElementVNode("li", { class: "nav-item d-none d-md-block px-1 text-muted" }, "|", -1)),
        _cache[9] || (_cache[9] = _createElementVNode("li", { class: "nav-item px-3" }, [
          _createElementVNode("span", {
            class: "text-white px-2 py-2 d-block",
            style: {"font-size":"small"}
          }, "WebApps, hosting, applications and custom software in Durban, South Africa.")
        ], -1))
      ])
    ])
  ]))
}
}

})