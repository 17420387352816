import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "navbar navbar-expand-lg navbar-light bg-white sticky-top",
  id: "navbar"
}
const _hoisted_2 = { class: "container-lg" }
const _hoisted_3 = {
  class: "collapse navbar-collapse",
  id: "navbarNavDropdown"
}
const _hoisted_4 = { class: "navbar-nav ms-auto mb-2 mb-lg-0" }
const _hoisted_5 = { class: "nav-item" }
const _hoisted_6 = { class: "nav-item" }
const _hoisted_7 = { class: "nav-item" }
const _hoisted_8 = { class: "nav-item" }
const _hoisted_9 = { class: "nav-item" }
const _hoisted_10 = { class: "nav-item" }

import { scrollTop } from "@/utils/functions"
    
export default /*@__PURE__*/_defineComponent({
  __name: 'Navbar',
  setup(__props) {

    function homeScroll () {
        if(window.scrollY == 0) {
            return
        }
        
        setTimeout(() => {
            scrollTop('')
        }, 50)
    }

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, {
        class: "navbar-brand logo-style",
        to: "/",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (homeScroll()))
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createElementVNode("span", { class: "text-primary" }, "/", -1),
          _createElementVNode("span", { class: "text-secondary" }, "/", -1),
          _createElementVNode("span", { class: "text-tertiary" }, "/", -1),
          _createTextVNode(" nologo studios ")
        ])),
        _: 1
      }),
      _cache[8] || (_cache[8] = _createElementVNode("button", {
        class: "navbar-toggler",
        type: "button",
        "data-bs-toggle": "collapse",
        "data-bs-target": "#navbarNavDropdown",
        "aria-controls": "navbarNavDropdown",
        "aria-expanded": "false",
        "aria-label": "Toggle navigation"
      }, [
        _createElementVNode("span", { class: "navbar-toggler-icon" })
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("ul", _hoisted_4, [
          _createElementVNode("li", _hoisted_5, [
            _createVNode(_component_router_link, {
              class: "nav-link",
              to: "/clients"
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode("Clients")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("li", _hoisted_6, [
            _createVNode(_component_router_link, {
              class: "nav-link",
              to: "/careers"
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode("Careers")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("li", _hoisted_7, [
            _createVNode(_component_router_link, {
              class: "nav-link",
              to: "/development"
            }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode("Development")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("li", _hoisted_8, [
            _createVNode(_component_router_link, {
              class: "nav-link",
              to: "/hosting"
            }, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createTextVNode("Hosting")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("li", _hoisted_9, [
            _createVNode(_component_router_link, {
              class: "nav-link",
              to: "/about-us"
            }, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createTextVNode("About Us")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("li", _hoisted_10, [
            _createVNode(_component_router_link, {
              class: "nav-link",
              to: "/#ContactUs"
            }, {
              default: _withCtx(() => _cache[7] || (_cache[7] = [
                _createTextVNode("Contact Us")
              ])),
              _: 1
            })
          ])
        ])
      ])
    ])
  ]))
}
}

})